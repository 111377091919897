.color-high {
  fill: #1960f5;
}
.light-green-80 {
  fill: #0277bd;
}
.light-green-60 {
  fill: #03a9f4;
}
.light-green-40 {
  fill: #42a5f5;
}
.light-green-20 {
  fill: #90caf9;
}

.color-Empty {
  fill: #dad9d9;
}
