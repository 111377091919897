@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&family=Protest+Revolution&family=Protest+Riot&display=swap');

.cap-count-bounce {
  margin-left: 10px;
  font-size: 24px;
  background: #1960f5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: heartbeat 2s ease-in-out infinite;
  transform-origin: center;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.wrapper {
  text-align: center;
}

.wrapper span {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  background: linear-gradient(to right, #1960f5 10%, #fbb216 50%, #fbb216 60%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s ease-in-out infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.protest-riot-regular {
  font-family: 'Protest Riot', sans-serif;
  font-weight: 400;
  font-style: normal;
}
