.react-calendar {
  height: auto;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-family: 'Roboto';
}

.react-calendar__tile,
react-calendar__month-view__days__day
  react-calendar__month-view__days__day--weekend {
  padding: 0;
}

.react-calendar__navigation {
  display: flex;
  margin-top: 10px;
  margin-bottom: 15px;
}

.react-calendar__viewContainer {
  width: 'inherit';
  padding-left: 20px;
  padding-right: 20px;
}

.react-calendar__navigation__arrow {
  border-radius: 10px;
  width: 45px;
  height: 45px;
  margin-top: 20px;
  cursor: pointer;
  border: 0;
  background-color: #1976d2;
  color: white;
}

.react-calendar__navigation__prev-button {
  margin-left: 20px;
}

.react-calendar__navigation__next-button {
  margin-right: 20px;
}

.react-calendar__navigation__label {
  border: 0;
  background: #1976d2;
  border-radius: 10px;
  border-color: #1976d2;
  border-style: solid;
  border-width: 1px;
  margin-top: 20px;
  font-size: x-large;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  color: white;
}

.react-calendar__month-view__weekdays {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 45px;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  font-size: larger;
  /* padding: 5px; */
}

.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none;
}

.react-calendar__month-view__days {
  height: inherit;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  height: 200px;
}

.react-calendar__tile {
  width: 10px;
  cursor: pointer;
  display: flex;
  font-size: x-large;
  background-color: white;
  -webkit-box-shadow: 0 0 5px white;
  box-shadow: 0 0 5px white;
  border: 1px solid #cecece;
}

.react-calendar__tile:hover {
  border-color: #1976d2;
}

abbr {
  font-size: x-large;
  display: none;
}

abbr[title] {
  display: block;
}

.calender-tile-date {
  font-style: 'arial';
  font-size: '24px';
  margin: '10px';
  position: relative;
  left: 86px;
  top: 89px;
  font-weight: 'lighter';
}

.react-calendar__tile--now {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
}
