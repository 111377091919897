.Phone {
  animation: ringing 5s ease-in-out infinite;
}

#p1 {
  transform-origin: bottom right;
  animation: reveal 5s ease-in-out infinite backwards;
}
#p2 {
  transform-origin: bottom right;
  animation: reveal 5s ease-in-out 0.2s infinite backwards;
}

@keyframes reveal {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  20% {
    opacity: 0;
    transform: scale(1);
  }
  22% {
    opcaity: 1;
    transform: scale(1.05);
  }
  26% {
    opacity: 1;
    transform: scale(1);
  }
  40% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes scaleup {
  20% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ringing {
  20% {
    transform: rotate(0deg);
  }
  21% {
    transform: rotate(10deg);
  }
  22% {
    transform: rotate(-10deg);
  }
  23% {
    transform: rotate(12deg);
  }
  24% {
    transform: rotate(-12deg);
  }
  25% {
    transform: rotate(12deg);
  }
  26% {
    transform: rotate(-12deg);
  }
  28% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  32% {
    transform: rotate(5deg);
  }
  34% {
    transform: rotate(-5deg);
  }
  36% {
    transform: rotate(2deg);
  }
  38% {
    transform: rotate(0deg);
  }
}

@keyframes widthAnimation {
  from {
    width: 0;
  }
  to {
    width: 490px;
  }
}
